import React from 'react';
import { css } from 'emotion';
import { colors, layout } from '../../shared/theme';
import { Link } from 'gatsby';

const SideNav = ({ pages, title, currentPath }) => (
  <div
    css={css`
      top: 2rem;
      width: 100%;
      margin-bottom: 2rem;
      ${layout.bp.medium} {
        position: sticky;
        max-width: 300px;
        margin-left: 3rem;
      }
    `}
  >
    {title && (
      <h2
        css={css`
          font-size: 1rem;
          font-weight: 600;
          margin-top: 0;
          margin-bottom: 1em;
        `}
      >
        {title}
      </h2>
    )}
    <div
      css={css`
        border-top: 1px solid ${colors.mid};
      `}
    >
      {pages.map(({ title, path }) => (
        <Link
          key={path}
          css={css`
            display: block;
            margin-top: 0.75em;
            color: ${path === currentPath ? colors.primary : colors.midPrimary};
            opacity: ${path === currentPath ? 1 : 0.5};
            text-decoration: none;
            font-weight: ${path === currentPath ? 600 : 400};
            font-size: 0.9rem;
            &:hover {
              text-decoration: underline;
            }
          `}
          to={path}
        >
          {title}
        </Link>
      ))}
    </div>
  </div>
);

export default SideNav;
