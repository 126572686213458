import React from 'react';
import { graphql } from 'gatsby';
import { css } from 'emotion';
import { layout } from '../shared/theme';
import Layout from '../components/Layout';
import Container from '../components/Container';
import SideNav from '../components/SideNav';

import MDXRenderer from 'gatsby-mdx/mdx-renderer';

export default function Template({ data, pageContext }) {
  const { mdx } = data;
  const { frontmatter, code } = mdx;
  const { legalPages } = pageContext;

  return (
    <Layout>
      <Container>
        <div
          className="legalPage"
          css={css`
            padding-top: 8rem;
            padding-bottom: 4rem;
            ${layout.bp.medium} {
              padding-top: 12rem;
              display: flex;
              flex-direction: row-reverse;
            }
          `}
        >
          <SideNav
            title="Legal pages"
            pages={legalPages}
            currentPath={frontmatter.path}
          />
          <div
            css={css`
              width: 100%;
            `}
          >
            <h1
              css={css`
                margin-top: 0;
                margin-bottom: 2rem;
              `}
            >
              {frontmatter.title}
            </h1>
            <MDXRenderer>{code.body}</MDXRenderer>
          </div>
        </div>
      </Container>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      id
      code {
        body
      }
      frontmatter {
        path
        title
      }
    }
  }
`;
